import { atom, selector } from 'recoil';

export const authState = atom({
  key: 'AUTH_STATE',
  default: {}
});

export const profileSelector = selector({
  key: 'PROFILE_SELECTOR',
  get: ({ get }) => {
    return get(authState).profile;
  }
});
