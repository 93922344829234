import React, { Suspense } from 'react';
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom';
import {
  fileUploadRoute,
  latestReleasePeriodRoute,
  managersListRoute,
  salesLaborDataRoute
} from './app/admin/admin-layout/SidebarConfig';
import {
  appChildRoute,
  dashboardChildRoute,
  employeeChildRoute,
  ytdChildRoute
} from './app/dashboard-layout/SidebarConfig';
import Login from './app/layouts/Login';
import NotFound from './app/layouts/Page404';
import { Spinner } from './app/shared/spinner/Spinner';

export const loginRoute = { path: 'login' };
export const dashboardRoute = { path: 'dashboard' };
export const adminRoute = { path: 'admin' };

const DashboardLayout = React.lazy(() => import('./app/dashboard-layout/DashboardLayout'));
const DashboardApp = React.lazy(() => import('./app/pl-report/DashboardApp'));
const YTDReportPage = React.lazy(() => import('./app/ytd-report/YTDReportPage'));
const DashboardReportPage = React.lazy(() => import('./app/dashboard-report/DashboardReportPage'));
const EmployeeReportPage = React.lazy(() => import('./app/employee-report/EmployeeReportPage'));

const AdminLayout = React.lazy(() => import('./app/admin/admin-layout/AdminLayout'));
const FileUpload = React.lazy(() => import('./app/admin/file-upload/FileUpload'));
const SalesLaborData = React.lazy(() => import('./app/admin/sales-labor-data/SalesLaborData'));
const ManagersListPage = React.lazy(() => import('./app/admin/managers-list/ManagersListPage'));
const LatestReleasePeriod = React.lazy(() =>
  import('./app/admin/latest-release-period/LatestReleasePeriod')
);

export default function Router() {
  const { search } = useLocation();

  return useRoutes([
    {
      path: `/${dashboardRoute.path}`,
      element: (
        <Suspense fallback={<Spinner />}>
          <DashboardLayout />
        </Suspense>
      ),
      children: [
        {
          element: (
            <Navigate
              to={{
                pathname: `/${dashboardRoute.path}/${appChildRoute.path}`,
                search
              }}
              replace
            />
          )
        },
        {
          ...appChildRoute,
          element: (
            <Suspense fallback={<Spinner />}>
              <DashboardApp />
            </Suspense>
          )
        },
        {
          ...ytdChildRoute,
          element: (
            <Suspense fallback={<Spinner />}>
              <YTDReportPage />
            </Suspense>
          )
        },
        {
          ...dashboardChildRoute,
          element: (
            <Suspense fallback={<Spinner />}>
              <DashboardReportPage />
            </Suspense>
          )
        },
        {
          ...employeeChildRoute,
          element: (
            <Suspense fallback={<Spinner />}>
              <EmployeeReportPage />
            </Suspense>
          )
        }
      ]
    },
    {
      path: `/${adminRoute.path}`,
      element: (
        <Suspense fallback={<Spinner />}>
          <AdminLayout />
        </Suspense>
      ),
      children: [
        {
          element: (
            <Navigate
              to={{
                pathname: `/${adminRoute.path}/${fileUploadRoute.path}`,
                search
              }}
              replace
            />
          )
        },
        {
          ...fileUploadRoute,
          element: (
            <Suspense fallback={<Spinner />}>
              <FileUpload />
            </Suspense>
          )
        },
        {
          ...salesLaborDataRoute,
          element: (
            <Suspense fallback={<Spinner />}>
              <SalesLaborData />
            </Suspense>
          )
        },
        {
          ...managersListRoute,
          element: (
            <Suspense fallback={<Spinner />}>
              <ManagersListPage />
            </Suspense>
          )
        },
        {
          ...latestReleasePeriodRoute,
          element: (
            <Suspense fallback={<Spinner />}>
              <LatestReleasePeriod />
            </Suspense>
          )
        }
      ]
    },
    {
      path: '/',
      element: <Outlet />,
      children: [
        { path: '404', element: <NotFound /> },
        { ...loginRoute, element: <Login /> },
        { path: '/', element: <Navigate to={{ pathname: `/${dashboardRoute.path}`, search }} /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
