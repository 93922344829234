import DateRangeIcon from '@mui/icons-material/DateRange';
import PeopleIcon from '@mui/icons-material/People';
import PublishIcon from '@mui/icons-material/Publish';
import TodayIcon from '@mui/icons-material/Today';

export const fileUploadRoute = { path: 'file-upload' };
export const salesLaborDataRoute = { path: 'sales-labor' };
export const managersListRoute = { path: 'managers-list' };
export const latestReleasePeriodRoute = { path: 'latest-release-period' };

export const iconAttributes = { width: 22, height: 22 };

const sidebarConfig = [
  {
    title: 'File upload',
    path: `/admin/${fileUploadRoute.path}`,
    icon: <PublishIcon sx={iconAttributes} />
  },
  {
    title: 'Sales labor data',
    path: `/admin/${salesLaborDataRoute.path}`,
    icon: <TodayIcon sx={iconAttributes} />
  },
  {
    title: 'Managers list',
    path: `/admin/${managersListRoute.path}`,
    icon: <PeopleIcon sx={iconAttributes} />
  },
  {
    title: 'Latest release period',
    path: `/admin/${latestReleasePeriodRoute.path}`,
    icon: <DateRangeIcon sx={iconAttributes} />
  }
];

export default sidebarConfig;
