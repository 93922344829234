import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PieChartIcon from '@mui/icons-material/PieChart';
import { iconAttributes } from '../admin/admin-layout/SidebarConfig';

export const appChildRoute = { path: 'app' };
export const ytdChildRoute = { path: 'ytd' };
export const dashboardChildRoute = { path: 'dashboard' };
export const employeeChildRoute = { path: 'employee' };

const sidebarConfig = [
  {
    title: 'P&L Report',
    path: `/dashboard/${appChildRoute.path}`,
    icon: <LocalAtmIcon sx={iconAttributes} />
  },
  {
    title: 'YTD Report',
    path: `/dashboard/${ytdChildRoute.path}`,
    icon: <PieChartIcon sx={iconAttributes} />
  },
  {
    title: 'Dashboard Report',
    path: `/dashboard/${dashboardChildRoute.path}`,
    icon: <PieChartIcon sx={iconAttributes} />
  },
  {
    title: 'New Hire Report',
    path: `/dashboard/${employeeChildRoute.path}`,
    icon: <PieChartIcon sx={iconAttributes} />
  }
];

export default sidebarConfig;
