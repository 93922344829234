import { Button, Card, Container, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import useResponsive from '../utils/useResponsive';

import Logo from '../../components/Logo';
import Page from '../../components/Page';
import { USER_TOKEN } from '../../envoronments/environment';
import { adminRoute, dashboardRoute } from '../../routes';
import { getProfile } from '../auth/RequireAuth';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setToken(event.target.value);
    setError('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getProfile(token)
      .then((data) => {
        debugger;
        const params = {
          search: createSearchParams({
            [USER_TOKEN]: token
          }).toString()
        };
        if (data.role === 'admin') {
          navigate({ ...params, pathname: `/${adminRoute.path}` });
        } else {
          navigate({ ...params, pathname: `/${dashboardRoute.path}` });
        }
      })
      .catch(() => setError('Invalid token'));
  };

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={process.env.PUBLIC_URL + '/static/illustration_login.png'} alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              Enter your details below.
            </Typography>

            <form autoComplete="off" noValidate>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="token"
                  type="text"
                  label="Token"
                  onChange={handleChange}
                  value={token}
                  helperText={error}
                  error={!!error}
                />
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Stack>
            </form>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
