import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { API_HOST, USER_TOKEN } from '../../envoronments/environment';
import { dashboardRoute, loginRoute } from '../../routes';
import { Spinner } from '../shared/spinner/Spinner';
import { authState } from './auth.store';

const ACCESS_STATUS = {
  PENDING: 1,
  ALLOWED: 2,
  REJECTED: 3
};

export default function RequireAuth({ children, roles = [] }) {
  const location = useLocation();
  const [hasAccess, setHasAccess] = useState(ACCESS_STATUS.PENDING);
  const [isLoggedIn, setIsLoggedIn] = useState(ACCESS_STATUS.PENDING);
  const [searchParams] = useSearchParams();
  const [{ profile }, setAuthState] = useRecoilState(authState);

  const checkTokenAndAccess = useCallback(() => {
    const token = searchParams.get(USER_TOKEN);

    getProfile(token)
      .then((data) => {
        const role = data.role;

        setIsLoggedIn(ACCESS_STATUS.ALLOWED);

        if (!profile?.token || profile?.token !== token) {
          setAuthState((prevState) => ({ ...prevState, profile: { ...data, token } }));
        }

        if (roles.includes(role)) {
          setHasAccess(ACCESS_STATUS.ALLOWED);
          return;
        }
        setHasAccess(ACCESS_STATUS.REJECTED);
      })
      .catch(() => {
        setIsLoggedIn(ACCESS_STATUS.REJECTED);
        setHasAccess(ACCESS_STATUS.REJECTED);
      });
  }, [roles, searchParams, setAuthState, profile]);

  useEffect(() => {
    checkTokenAndAccess();

    return () => {
      setIsLoggedIn(ACCESS_STATUS.PENDING);
      setHasAccess(ACCESS_STATUS.PENDING);
    };
  }, [location, checkTokenAndAccess]);

  if (hasAccess === ACCESS_STATUS.REJECTED && isLoggedIn === ACCESS_STATUS.REJECTED) {
    return <Navigate to={`/${loginRoute.path}`} state={{ from: location }} replace />;
  }

  if (hasAccess === ACCESS_STATUS.REJECTED && isLoggedIn === ACCESS_STATUS.ALLOWED) {
    return <Navigate to={{ pathname: `/${dashboardRoute.path}`, search: location.search }} />;
  }

  if (hasAccess === ACCESS_STATUS.ALLOWED && isLoggedIn === ACCESS_STATUS.ALLOWED) {
    return children;
  }

  return <Spinner />;
}

RequireAuth.propTypes = {
  children: PropTypes.element,
  roles: PropTypes.array
};

export const getProfile = (token) => {
  return fetch(`${API_HOST}/profile?${USER_TOKEN}=${token}`).then((response) => {
    if (response.status === 401) {
      throw new Error(response);
    }
    return response.json();
  });
};
